<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-schedule-mail-96.png"
          class="h-20 ml-2 inline-block"
        />
        ارسال پست
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">
        {{ channel.cid }}
      </div>
    </template>
    <template #default>
      <!-- ANCHOR - post -->
      <ChannelSettingCard>
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-mms-96.png" class="inline-block h-20" />
          متن و فایل
        </div>
        <p class="mb-2">
          برای ثبت بنر ابتدا دکمه زیر را زده و سپس بنر را در تلگرام به pv ربات ارسال کنید
          و سپس کلید بارگذاری مجدد را بزنید
        </p>
        <div v-if="'ok' in res" class="my-5">
          <p v-if="res.ok">
            اکنون پیام مورد نظر خود را به ربات
            <a
              :href="'https://t.me/' + res.result.from.username"
              class="text-sky-500 font-bold"
              target="_blank"
            >
              {{ res.result.from.first_name }}
            </a>
            ارسال کنید
          </p>
          <p v-else>
            مشکلی پیش آمد ربات نتوانست به شما پیام دهد. احتمالا ربات را بلاک کرده‌اید یا
            هنوز آن را استارت نکرده‌اید. به pv ربات بروید و آن را استارت کنید
          </p>
        </div>
        <div v-if="'ok' in sendRes" class="my-5">
          <p v-if="sendRes.ok">بنر توسط ربات ارسال شد</p>
          <p v-else>
            مشکلی پیش آمد ربات نتوانست به شما پیام دهد. احتمالا ربات را بلاک کرده‌اید یا
            هنوز آن را استارت نکرده‌اید. به pv ربات بروید و آن را استارت کنید
          </p>
        </div>
        <div class="flex gap-2">
          <Button class="mt-3 text-lg inline-flex" color="amber" @click="setPostByBot">
            ارسال پست به ربات
          </Button>
          <Button class="mt-3 text-lg inline-flex" color="teal" @click="setPreviewtByBot">
            ارسال پیشنمایش در ربات
          </Button>
          <Button class="mt-3 text-lg inline-flex" color="cyan" @click="getData">
            بارگذاری مجدد
          </Button>
        </div>
        <div class="texl-xl mt-5">
          نوع پست:
          <span class="font-bold">{{ type_list[post.file_type] ?? post.file_type }}</span>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea v-model="post.text" title="متن پست" class="col-span-2" />
        </div>

        <template #help>
          <ul class="list-inside list-disc">
            <li class="py-1">
              برای مشخص کردن فایل
              <span class="font-bold">مانند عکس، فیلم و...</span>
              کلید
              <span class="font-bold">ارسال پست به ربات</span>
              را بزنید و سپس پست را داخل تلگرام به ربات ارسل کنید سپست به این صفحه برگردید
              و سایر مشخصات را تنظیم کنید
            </li>
          </ul>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - sign -->

      <ChannelSettingCard>
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-sign-up-96.png" class="inline-block h-20" />
          امضا
        </div>
        <div>
          <MnrRadioSlider v-model="post.with_sign" value="no" name="with_sign">
            بدون امضا
          </MnrRadioSlider>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div class="bg-blueGray-50 border border-blueGray-200 rounded-md p-3">
            <MnrRadioSlider v-model="post.with_sign" value="channel" name="with_sign">
              امضا کانال
            </MnrRadioSlider>
            <p>
              {{ channel.signature }}
            </p>
          </div>
          <div class="bg-blueGray-50 border border-blueGray-200 rounded-md p-3">
            <MnrRadioSlider v-model="post.with_sign" value="user" name="with_sign">
              امضا شما
            </MnrRadioSlider>
            <p>
              {{ channel.userChannel?.signature }}
            </p>
          </div>
        </div>

        <template #help>
          <ul class="list-inside list-disc">
            <li class="py-1">
              <span class="font-bold">جایگذاری لحظه‌ای</span>
              تنها برای امضای کانال امکانپذیر است
            </li>
            <li class="py-1">
              <span class="font-bold">امضای کانال</span>
              را میتوانید برای جایگذاری لحظه ای استفاده کنید همچنین موقع ارسال پست از پنل
              میتوانید انتخاب کنید تا به زیر پست اضافه شود
            </li>
            <li class="py-1">
              <span class="font-bold">امضای شما</span>
              را میتوانید در هنگام ارسال پست از پنل انتخاب کنید تا در زیر پست قرار گیرد
            </li>
          </ul>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - like keys -->
      <ChannelSettingCard>
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-smiling-face-with-heart-96.png"
            class="inline-block h-20"
          />
          کلید های نظر سنجی
        </div>
        <div>
          <MnrCheckSlider v-model:checked="post.with_key">روشن</MnrCheckSlider>
        </div>
        <div
          v-for="(keyRow, index) in rateKey"
          :key="index"
          class="
            p-1
            sm:p-3
            border
            bg-blueGray-50
            border-blueGray-200
            mt-5
            rounded
            shadow-md
          "
        >
          <Button
            class="rounded-full h-6 text-sm w-auto mb-3 inline-block"
            color="red"
            @click="rateKey.splice(index, 1)"
          >
            × حذف سطر
          </Button>
          <div class="flex gap-1" dir="ltr">
            <div
              v-for="(keyCol, indexCol) in keyRow"
              :key="indexCol"
              class="p-1 sm:p-2 w-full bg-blueGray-100 border border-blueGray-200 rounded"
              dir="rtl"
            >
              <Button
                class="rounded-full h-5 w-5 mb-3 inline-block p-0"
                color="red"
                @click="
                  keyRow.splice(indexCol, 1);
                  if (keyRow.length < 1) rateKey.splice(index, 1);
                "
              >
                ×
              </Button>
              <MnrText v-model="keyCol.value" placeholder="اموجی یا کلمه" />
            </div>
            <div class="w-7 flex-shrink-0" v-show="keyRow.length < 4">
              <Button
                class="rounded-md h-full w-full"
                color="cyan"
                @click="keyRow.push({ value: '' })"
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <Button
          class="inline-block w-auto mt-5"
          color="teal"
          @click="rateKey.push([{ value: '' }])"
        >
          اضافه کردن سطر
        </Button>

        <template #help>
          <p>
            این کلید ها زیر پست ها قرار میگیرد و کاربران با زدن آنها به آنها امتیاز
            می‌دهند. این کلید ها را با کلید های لینک دار اشتباه نگیرید
          </p>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - link keys -->
      <ChannelSettingCard>
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-external-link-96.png"
            class="inline-block h-20"
          />
          کلید های لینک دار
        </div>
        <div>
          <MnrCheckSlider v-model:checked="post.with_link_keys">روشن</MnrCheckSlider>
        </div>
        <div
          v-for="(keyRow, index) in linkKeys"
          :key="index"
          class="
            p-1
            sm:p-3
            border
            bg-blueGray-50
            border-blueGray-200
            mt-5
            rounded
            shadow-md
          "
        >
          <Button
            class="rounded-full h-6 text-sm w-auto mb-3 inline-block"
            color="red"
            @click="linkKeys.splice(index, 1)"
          >
            × حذف سطر
          </Button>
          <div class="flex gap-1" dir="ltr">
            <div
              v-for="(keyCol, indexCol) in keyRow"
              :key="indexCol"
              dir="rtl"
              class="p-1 sm:p-2 w-full bg-blueGray-100 border border-blueGray-200 rounded"
            >
              <Button
                class="rounded-full h-5 w-5 mb-3 inline-block p-0"
                color="red"
                @click="
                  keyRow.splice(indexCol, 1);
                  if (keyRow.length < 1) linkKeys.splice(index, 1);
                "
              >
                ×
              </Button>
              <MnrText v-model="keyCol.text" placeholder="متن کلید" />
              <MnrText
                inputDir="ltr"
                class="mt-2"
                v-model="keyCol.link"
                placeholder="لینک کلید"
              />
            </div>
            <div class="w-7 flex-shrink-0" v-show="keyRow.length < 2">
              <Button
                class="rounded-md h-full w-full"
                color="cyan"
                @click="keyRow.push({ text: '', link: '' })"
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <Button
          class="inline-block w-auto mt-5"
          color="teal"
          @click="linkKeys.push([{ text: '', link: '' }])"
        >
          اضافه کردن سطر
        </Button>

        <template #help>
          <p>از این قسمت میتوانید کلید های لینک دار در زیر پست قرار دهید</p>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - send option -->
      <ChannelSettingCard v-if="!post.sended_at">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-send-96.png" class="inline-block h-20" />
          ارسال
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            :options="options.send"
            v-model="inputs.send"
            title="گزینه های ارسال"
            placeholder="یک مورد را انتخاب کنید"
          />
          <MnrSelect
            v-if="inputs.send == 'queue'"
            v-model="inputs.queue"
            :options="channel.queues"
            oText="name"
            oValue="id"
            title="صف مورد نظر"
            placeholder="یک مورد را انتخاب کنید"
          />
        </div>
        <div
          class="grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-3 mt-5"
          v-if="inputs.send == 'time'"
        >
          <MnrNumber
            v-model="sendOn.day"
            class=""
            placeholder="روز"
            hint="روز"
            :counter="false"
          />
          <MnrSelect
            v-model="sendOn.month"
            :options="options.month"
            class="col-span-2 sm:col-auto"
            placeholder="ماه"
            hint="ماه"
          />
          <MnrNumber
            v-model="sendOn.year"
            placeholder="سال"
            hint="سال"
            :counter="false"
          />
          <MnrText
            v-model="sendOn.time"
            class="col-span-2 sm:col-auto"
            hint="ساعت"
            type="time"
          />
        </div>

        <template #help>
          <p>
            در این قسمت میتوانید مشخص کنید آیا پست اکنون ارسال شود یا به صف منتقل شود و یا
            در ساعت مورد نظر ارسال شود
          </p>
        </template>
      </ChannelSettingCard>
    </template>

    <template #bottom>
      <Button @click="submit">
        <template v-if="post.sended_at">ویرایش پست در کانال</template>
        <template v-else>ثبت و اعمال</template>
      </Button>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrTextarea from "@/components/mnr/MnrTextarea.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import MnrRadioSlider from "@/components/mnr/MnrRadioSlider.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import ChannelSettingCard from "@/components/ChannelSettingCard.vue";
import _ from "lodash";
import MnrNumber from "@/components/mnr/MnrNumber.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrText,
    MnrTextarea,
    MnrCheckSlider,
    MnrRadioSlider,
    MnrSelect,
    ChannelSettingCard,
    MnrNumber,
  },
  data() {
    return {
      firstLoading: true,
      error: [],
      channel: {},
      post: {},
      res: {},
      sendRes: {},
      rateKey: [],
      linkKeys: [],
      sendOn: {},
      inputs: {
        send: "draft",
        queue: null,
      },
      options: {
        send: [
          {
            text: "پیشنویس",
            value: "draft",
          },
          {
            text: "اکنون ارسال شود",
            value: "send",
          },
          {
            text: "انتقال به صف",
            value: "queue",
          },
          {
            text: "ارسال در زمان مشخص",
            value: "time",
          },
        ],
        month: [
          {
            text: "فروردین",
            hint: 1,
            value: 1,
          },
          {
            text: "اردیبهشت",
            hint: 2,
            value: 2,
          },
          {
            text: "خرداد",
            hint: 3,
            value: 3,
          },
          {
            text: "تیر",
            hint: 4,
            value: 4,
          },
          {
            text: "مرداد",
            hint: 5,
            value: 5,
          },
          {
            text: "شهریور",
            hint: 6,
            value: 6,
          },
          {
            text: "مهر",
            hint: 7,
            value: 7,
          },
          {
            text: "آبان",
            hint: 8,
            value: 8,
          },
          {
            text: "آذر",
            hint: 9,
            value: 9,
          },
          {
            text: "دی",
            hint: 10,
            value: 10,
          },
          {
            text: "بهمن",
            hint: 11,
            value: 11,
          },
          {
            text: "اسفند",
            hint: 12,
            value: 12,
          },
        ],
      },
      list_pro: {
        silver: "نقره ای",
        golden: "طلایی",
      },
      type_list: {
        text: "متن",
        photo: "عکس",
        video: "فیلم",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get(
          `api/owner-channels/${$this.$route.params.channelId}/post/${$this.$route.params.postId}`,
          {
            params: {
              id: $this.$store.state.user.id,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.setValues(response);
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    submit() {
      let $this = this;
      $this.$axios
        .put(
          `api/owner-channels/${$this.$route.params.channelId}/post/${$this.$route.params.postId}`,
          {
            id: $this.$store.state.user.id,
            post: $this.post,
            inputs: $this.inputs,
            rateKey: $this.rateKey,
            linkKeys: $this.linkKeys,
            sendOn: $this.sendOn,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.setValues(response);
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    setPostByBot() {
      let $this = this;
      $this.$axios
        .put(
          `api/owner-channels/${$this.$route.params.channelId}/post/${$this.$route.params.postId}/set-post`,
          {
            id: $this.$store.state.user.id,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.res = response.data;
          // $this.setValues(response);
          // $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          // $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .finally(function () {});
    },
    setPreviewtByBot() {
      let $this = this;
      $this.$axios
        .post(
          `api/owner-channels/${$this.$route.params.channelId}/post/${$this.$route.params.postId}/send-preview`,
          {
            id: $this.$store.state.user.id,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.sendRes = response.data;
          // $this.setValues(response);
          // $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          console.log(error);
          // $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .finally(function () {});
    },
    setValues(response) {
      let $this = this;
      $this.channel = response.data.channel;
      $this.post = response.data.post;
      $this.sendOn = response.data.send_on;

      $this.rateKey = $this.rateKey ?? [];
      $this.rateKey = _.map(response.data.post.keys_json, function (item) {
        return _.map(item, function (key) {
          return { value: key };
        });
      });
      $this.linkKeys = response.data.post.link_keys_json ?? [];
    },
  },
};
</script>
